/**
 * Const with params element
 * Cookies banner wrapper component
 * Display a cookie banner wrapper
 */
/* Import section */
import React from "react"
import PropTypes from "prop-types"
import Cookiesbanner from "./cookiesbanner"

/* Declaration function */
const Cookiesbannerwrapper = ({ children }) => {
  return (
    <>
    {children}
    <Cookiesbanner />
    </>
  )
}

/* Function propTypes declaration */
Cookiesbannerwrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

/* Export function */
export default Cookiesbannerwrapper
