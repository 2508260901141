// wrapPageElement for cookies banner
import React from "react"
import Cookiesbannerwrapper from "./src/components/cookiesbannerwrapper"


export const wrapPageElement = ({ element, props }) => (
  <Cookiesbannerwrapper {...props}>{element}</Cookiesbannerwrapper>
);


