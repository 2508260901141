// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-appointment-confirmation-js": () => import("./../../../src/pages/appointment-confirmation.js" /* webpackChunkName: "component---src-pages-appointment-confirmation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-create-account-js": () => import("./../../../src/pages/create-account.js" /* webpackChunkName: "component---src-pages-create-account-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-center-js": () => import("./../../../src/pages/learning-center.js" /* webpackChunkName: "component---src-pages-learning-center-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-lost-password-js": () => import("./../../../src/pages/lost-password.js" /* webpackChunkName: "component---src-pages-lost-password-js" */),
  "component---src-pages-medical-records-request-js": () => import("./../../../src/pages/medical-records-request.js" /* webpackChunkName: "component---src-pages-medical-records-request-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-of-service-v-1-js": () => import("./../../../src/pages/terms-of-service-v1.js" /* webpackChunkName: "component---src-pages-terms-of-service-v-1-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-virtual-care-plans-js": () => import("./../../../src/pages/virtual-care/plans.js" /* webpackChunkName: "component---src-pages-virtual-care-plans-js" */),
  "component---src-pages-virtual-care-quality-care-js": () => import("./../../../src/pages/virtual-care/quality-care.js" /* webpackChunkName: "component---src-pages-virtual-care-quality-care-js" */),
  "component---src-pages-virtual-care-sign-up-js": () => import("./../../../src/pages/virtual-care/sign-up.js" /* webpackChunkName: "component---src-pages-virtual-care-sign-up-js" */),
  "component---src-templates-clinic-post-js": () => import("./../../../src/templates/ClinicPost.js" /* webpackChunkName: "component---src-templates-clinic-post-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-post-list-js": () => import("./../../../src/templates/PostList.js" /* webpackChunkName: "component---src-templates-post-list-js" */),
  "component---src-templates-tag-post-js": () => import("./../../../src/templates/TagPost.js" /* webpackChunkName: "component---src-templates-tag-post-js" */)
}

