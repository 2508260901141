/**
 * Class element
 * Cookies banner component
 * Display a cookie banner
 */
/* Import section */
import React, { Component } from "react"
import { IconContext } from "react-icons"
import { FiX } from "react-icons/fi"
import Cookies from 'universal-cookie'
import "../styles/components/cookiesbanner.scss"

const cookies = new Cookies()

/* Declaration main class with closeBanner  */
class Cookiesbanner extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showBanner: true
    }
  }

  componentDidMount() {
    (cookies.get('viewed_cookie_notice') !== undefined && this.setState({ showBanner: cookies.get('viewed_cookie_notice')}))
  }

  closeBanner(e){
    cookies.set('viewed_cookie_notice', false, { path: '/'})
    this.setState({ showBanner: cookies.get('viewed_cookie_notice') })
  }

  render () {
    var classNameBanner=(this.state.showBanner === true) ? "":"hidden";

    return (
      <div className={`cookies-banner ${classNameBanner}`}>
        <div className="content-cookies-banner">
          <p>Our website uses cookies. By continuing, we assume your permission to deploy cookies, as detailed in our <a href="/privacy-policy/" rel="nofollow" title="Privacy &amp; Cookies Policy">privacy and cookies policy.</a></p>
        </div>
        <div className="consent-cookies">
          <button className="button-consent-cookies" onClick={() => this.closeBanner()} aria-label="Close the cookies banner">
              <IconContext.Provider value={{ className: "icon-closed-consent" }}>
                <i aria-hidden="true"><FiX /></i>
              </IconContext.Provider>
          </button>
        </div>
      </div>
    )
  }
}

/* Export class */
export default Cookiesbanner
